function Loader() {
  return (
    <>
      {/* <Spinner animation="border" size="sm" /> */}
      {/* <Spinner animation="grow" size="sm" /> */}
      {/* <Spinner animation="grow" /> */}

      <section className="wrapper">
        <div className="loading">
          <div></div>
          <div></div>
        </div>
      </section>
    </>
  );
}

export default Loader;
